export const PARTNERS_STATUS_ACTIVE = 1;
export const PARTNERS_STATUS_INACTIVE = 2;

export const PARTNERS_STATUS_VALUES = {
  [PARTNERS_STATUS_ACTIVE]: 'active',
  [PARTNERS_STATUS_INACTIVE]: 'in_active'
};

export type PartnersStatusType = keyof typeof PARTNERS_STATUS_VALUES;

export const EVENT_COLORS = ['#d40001', '#32b579'];

export const getPartnerStatusColor = (status: number) => {
  switch (status) {
    case PARTNERS_STATUS_ACTIVE:
      return EVENT_COLORS[1];
    case PARTNERS_STATUS_INACTIVE:
      return EVENT_COLORS[0];
  }
};
